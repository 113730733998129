@import '../colorScheme.scss';

$out-of-range-color: $systemGrays03LabelTertiary !default;
$out-of-range-bg-color: $systemGrays08FillQuaternary !default;

$calendar-border: $componentsDivider !default;
$cell-border: $componentsDivider !default;

$border-color: $componentsDivider !default;

$segment-width: percentage(1 / 7) !default;

$time-selection-color: $componentsBgGrouped02 !default;
$time-selection-bg-color: rgba(0,0,0, .50) !default;
$date-selection-bg-color: rgba(0,0,0, .10) !default;


$event-bg: $colorsBrandOrange01Primary !default;
$event-border: $colorsBrandOrange01Primary !default;
$event-outline: $colorsBrandOrange01Primary !default;
$event-color: #fff !default;
$event-border-radius: 5px !default;
$event-padding: 2px 5px !default;
$event-zindex: 4 !default;

$btn-color: $systemGrays01LabelPrimary !default;
$btn-bg: $componentsBgGrouped02 !default;
$btn-border: $componentsDivider !default;

$current-time-color: $colorsBrandTurquoise01Primary !default;

$rbc-css-prefix: rbc-i !default;

$today-highlight-bg: $colorsBrandOrange07FillTertiary !default;

$orangeBand: $colorsBrandOrange01Primary;