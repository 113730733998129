$dot-bg: var(--colorsWhite);
$discription-color: var(--systemGrays02LabelSecondary);
$check-active-color: var(--colorsBrandOrange01Primary);
$check-deactive-color: var(--systemGrays02LabelSecondary);
$checkbox-bg-deactive-color: var(--componentsBgGrouped02);

.sliderWrapper {
  > div > div {
    padding: 0 !important;
    overflow: hidden !important;
    border-radius: 12px 12px 0 0 !important;
  }
}

/* dots */
.slickDots {
  display: flex !important;
  justify-content: center;
  width: 100%;
  margin: 0;
  margin-top: -36px;
  padding: 0;
  list-style: none;

  [class="slick-active"] {
    button {
      &::before {
        opacity: 1;
      }
    }
  }

  li {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    button {
      display: block;
      width: 20px;
      height: 20px;
      padding: 5px;
      border: 0;
      outline: none;
      background: transparent;
      color: transparent;
      font-size: 0;
      line-height: 0;
      cursor: pointer;

      &:hover {
        &::before {
          opacity: 1;
        }
      }

      &::before {
        content: "•";
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        opacity: 0.25;
        color: $dot-bg;
        font-family: slick;
        font-size: 28px;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        line-height: 20px;
        text-align: center;
      }
    }
  }
}

.checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    display: inline-flex;
    position: relative;
    padding: 0;
    cursor: pointer;
  }

  // Box.
  & + label::before {
    content: "";
    display: inline-block;
    display: inline-flex;
    align-self: center;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    border: 2px solid $check-deactive-color;
    border-radius: 3px;
    background: $checkbox-bg-deactive-color;
    vertical-align: text-top;
  }

  // Box checked
  &:checked + label::before {
    border-color: $check-active-color;
    background: $check-active-color;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label::after {
    content: "";
    display: inline-flex;
    position: absolute;
    left: 3px;
    align-self: center;
    width: 2px;
    height: 2px;
    transform: rotate(45deg);
    background: $checkbox-bg-deactive-color;
    box-shadow:
      2px 0 0 $checkbox-bg-deactive-color,
      4px 0 0 $checkbox-bg-deactive-color,
      4px -2px 0 $checkbox-bg-deactive-color,
      4px -4px 0 $checkbox-bg-deactive-color,
      4px -6px 0 $checkbox-bg-deactive-color,
      4px -8px 0 $checkbox-bg-deactive-color;
  }
}
