$arrow-background: #434343;

.arrow-button {
  display: flex;
  position: absolute;
  z-index: 1;
  top: calc(50% - 15px);
  padding: 4px;
  border: 0;
  border-radius: 50%;
  opacity: 0.7;
  background: $arrow-background;
  cursor: pointer;
  scroll-behavior: smooth;

  @media (max-width: theme("screens.md")) {
    display: none;
  }
}

.arrow-button:hover {
  opacity: 1;
}

.arrow-left {
  left: -15px;
}

.arrow-right {
  right: -15px;
}

.hideScrollbar {
  display: grid;
  grid-auto-flow: column;
  align-items: flex-start;
  justify-content: left;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scroll-behavior: smooth;

  // hide scroll bar ie, firefox, chrome, safari
  scrollbar-width: none;

  > * {
    min-height: 1px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
