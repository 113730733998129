$backdropBackground: var(--overlayBGModal);

.backdrop {
  position: fixed;
  z-index: 1001;
  top: -100vh;
  right: 0;
  bottom: 0;
  left: 0;
  height: 300vh;
  overflow-y: scroll;
  background: $backdropBackground;
  -webkit-overflow-scrolling: touch;
  backdrop-filter: saturate(180%) blur(4px);
}
