@import '../colorScheme.scss';

$datepicker__background-color: $componentsBgGrouped02 !default;
$datepicker__border-color: $componentsDivider !default;
$datepicker__highlighted-color: $colorsBrandTurquoise01Primary !default;
$datepicker__muted-color: $systemComponentsDisabled !default;
$datepicker__selected-color: $colorsBrandOrange01Primary !default;
$datepicker__text-color: $systemGrays01LabelPrimary !default;
$datepicker__header-color: $systemGrays01LabelPrimary !default;
$datepicker__navigation-disabled-color: $systemComponentsDisabled !default;

$datepicker__border-radius: 0.3rem !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 0.8rem !default;
$datepicker__font-family: "Helvetica Neue", helvetica, arial, sans-serif !default;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-button-size: 32px !default;
$datepicker__triangle-size: 8px !default;
