$selectedColor: var(--colorsBrandRed01Primary);

.radio-card-btn {
  width: 100%;
  height: 100%;

  input[type="radio"] {
    display: none;

    &~label {
      cursor: pointer;
    }
  }

  label {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 8px 0;
    border: solid 1px var(--colorsBrandWarmBlack04LabelQuaternary);
    border-radius: 6px;

    .radio-card-btn__btn::after {
      content: "";
      width: 10px;
      height: 10px;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      border-radius: 50%;
      background-color: $selectedColor;
    }

    .radio-card-btn__label {
      font-size: 16px;
    }

    .radio-card-btn__image {
      margin-right: 12px;
    }
  }

  input[type="radio"]:checked+label {
    border-color: $selectedColor;

    .radio-card-btn__btn {
      border-color: $selectedColor;

      &::after {
        transform: scale(1);
      }
    }
  }

  input[type="radio"]:disabled~label {
    cursor: not-allowed;

    .radio-card-btn__btn {
      border-color: var(--colorsBrandWarmBlack05FillPrimary);
      background-color: var(--colorsBrandWarmBlack07FillTertiary);
    }

    .radio-card-btn__label {
      color: var(--colorsBrandWarmBlack02LabelSecondary);
    }

    .radio-card-btn__image {
      filter: grayscale(100%);
      opacity: 0.5;
    }
  }
}