$dotColor: #a7a7a7;

.slick-slider {
  display: block;
  position: relative;
  box-sizing: border-box;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  touch-action: pan-y;
}

.slick-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0 0 16px 0;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-track {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track::before,
.slick-track::after {
  content: "";
  display: table;
}

.slick-track::after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  height: 100%;
  min-height: 1px;
  float: left;

  > * {
    display: flex;
  }
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* dots */
.slick-dots {
  display: flex !important;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.slick-dots li {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  border: 0;
  outline: none;
  background: transparent;
  color: transparent;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover::before,
.slick-dots li button:focus::before {
  opacity: 1;
}

.slick-dots li button::before {
  content: "•";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  opacity: 0.25;
  color: #616161;
  font-family: slick;
  font-size: 28px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: 20px;
  text-align: center;
}

.slick-dots li.slick-active button::before {
  opacity: 0.75;
  color: $dotColor;
}

@media only screen and (max-width: 600px) {
  .slick-dots li button {
    display: none;
  }
}
