.public-DraftEditorPlaceholder-inner {
  position: absolute;
  z-index: 0;
  color: #9197a3;
}

.DraftEditor-editorContainer {
  position: relative;
  z-index: 1;
}

.storylog-RichEditor-editor {
  position: relative;
  margin: 0 auto;
  margin-top: 10px;
  cursor: text;
}

.storylog-RichEditor-editor h3 {
  margin: 1.2em 0;
  font-size: 1.3em;
}

.storylog-RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
  padding-bottom: 20px;
}

.storylog-RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

blockquote {
  display: block;
  padding: 0 50px;
  font-style: italic;
  font-weight: 300;
  text-align: center;
}

blockquote span,
blockquote p {
  font-style: italic !important;
  font-weight: 300 !important;
  text-decoration: none !important;
}

blockquote a {
  color: #faac21;
  text-decoration: underline !important;
}

blockquote::after,
blockquote::before {
  content: " ";
  display: block;
  position: relative;
  width: 60%;
  height: 50px;
  margin: 10px auto;
  background-image: "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0ODguMjU2IiBoZWlnaHQ9IjQ5LjA5OCIgdmlld0JveD0iMCAwIDQ4OC4yNTYgNDkuMDk4Ij48cGF0aCBmaWxsPSJub25lIiBkPSJNMCAwaDQ4OC4yNTZ2NDkuMDk4SDB6Ii8+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjQzdDOUNCIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGQ9Ik0yMTMuNzg4IDI2LjE3NEg4LjIwNE00ODAuMDUyIDI2LjE3NEgyNzQuNDY4Ii8+PGcgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGw9IiNDN0M5Q0IiPjxwYXRoIGQ9Ik0yNDguODI2IDI5LjI2NGgtMi44MDVsMS4yMzQtOS40M2gzLjY1MWMtLjY5OSAzLjE3MS0xLjM5IDYuMzA3LTIuMDggOS40M3pNMjQxLjcyOSAyOS4yNjJoLTIuNzk4bDEuMjItOS40MjhoMy42NTZsLTIuMDc4IDkuNDI4eiIvPjwvZz48L3N2Zz4=)";
  background-position: center;
  background-size: cover;
}

@media (max-width: 800px) {
  blockquote::after,
  blockquote::before {
    width: 80%;
  }
}

.indent {
  text-indent: 50px;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.public-DraftEditor-content .storylog-editor:first-child {
  margin-top: 0;
  padding-top: 0;
}

.storylog-RichEditor-editor .public-DraftStyleDefault-pre {
  padding: 20px;
  background-color: rgb(0 0 0 / 5%);
  font-size: 16px;
}

.public-DraftStyleDefault-unorderedListItem,
.public-DraftStyleDefault-orderedListItem {
  margin-bottom: 10px;
}

.storylog-editor-action {
  position: fixed;
  top: 5px;
  left: 5px;
}

.storylog-editor-action button {
  display: block;
}

.storylog-side-toolbar {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 10px;
}

@media (max-width: 768px) {
  .storylog-side-toolbar {
    right: 10px;
  }
}

.storylog-add-button-box {
  float: right;
}

.storylog-add-button-box button {
  float: left;
}

.storylog-sb-button {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 2px;
  padding: 0;
  float: right;
  transition: all 0.2s ease;
  border: 1px solid #5b5b5b;
  border-radius: 20px;
  background: none;
  background-color: #fff;
  color: #6d6d6d;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.storylog-sb-button:focus {
  border-color: #08c;
  outline: none;
  color: #08c;
}

.storylog-sb-button:hover {
  color: green;
}

.storylog-sb-button.storylog-sb-img-button {
  background: none;
  background-color: white;
}

.storylog-sb-button svg path {
  stroke-width: 0;
  fill: #545454;
}

@keyframes pop-upwards {
  0% {
    transform: matrix(0.97, 0, 0, 1, 0, 12);
    opacity: 0;
  }

  20% {
    transform: matrix(0.99, 0, 0, 1, 0, 2);
    opacity: 0.7;
  }

  40% {
    transform: matrix(1, 0, 0, 1, 0, -1);
    opacity: 1;
  }

  70% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }

  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
}

@keyframes pop-downwards {
  0% {
    transform: matrix(0.97, 0, 0, 1, 0, -12);
    opacity: 0;
  }

  20% {
    transform: matrix(0.99, 0, 0, 1, 0, -2);
    opacity: 0.7;
  }

  40% {
    transform: matrix(1, 0, 0, 1, 0, 1);
    opacity: 1;
  }

  70% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }

  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
}

.storylog-editor-toolbar {
  visibility: hidden;
  position: absolute;
  z-index: 2;
  max-width: 100%;
  border-radius: 5px;
  background: #1a1a1a;
  box-shadow: 0 1px 3px 0 #747171;
  color: #fff;
  cursor: auto;
}

.storylog-editor-toolbar.storylog-editor-toolbar--isopen {
  visibility: visible;
}

.storylog-editor-toolbar.storylog-editor-toolbar--linkinput {
  width: 50vw;
  max-width: 100vw;
  animation: pop-downwards 200ms forwards linear;
}

@media (max-width: 768px) {
  .storylog-editor-toolbar.storylog-editor-toolbar--linkinput {
    left: 10vw !important;
    width: 80vw;
  }
}

.storylog-editor-toolbar .storylog-url-input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 5px;
  padding-right: 11px;
  border: none;
  border-radius: 2px;
  background: #1a1a1a;
  color: #fff;
  font-size: 0.9em;
  font-weight: 100;
}

.storylog-editor-toolbar .storylog-url-input:focus {
  outline: none;
}

.storylog-editor-toolbar .storylog-url-input-close {
  position: absolute;
  top: 2.5px;
  right: 8px;
  font-size: 20px;
  cursor: pointer;
}

.storylog-editor-toolbar-unlink-button-box {
  display: inline-block;
  min-width: 120px;
}

.storylog-editor-toolbar-unlink-button,
.storylog-editor-toolbar-edit-button {
  margin-left: 10px;
}

.storylog-editor-toolbar-edit-link {
  width: 350px;
  max-width: 100%;
  padding: 3px 0;
  font-size: 0.8em;
  text-align: center;
}

.storylog-editor-toolbar-edit-link a {
  padding: 8px 20px;
  overflow: hidden;
  color: #fff;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.storylog-editor-toolbar-edit-link::after {
  top: -5px;
  bottom: initial;
  transform: rotate(180deg);
}

.storylog-editor-toolbar-edit-link button {
  display: inline-block;
  margin: 5px 0;
  padding: 0 5px;
  border: none;
  background: none;
  color: #fff;
  cursor: pointer;
}

.storylog-editor-toolbar-edit-link button.storylog-RichEditor-styleButton-left {
  border-left: 1px solid #fff;
}

.storylog-RichEditor-styleButton-left {
  border-left: 1px solid #555;
}

.storylog-RichEditor-styleButton-right {
  border-right: 1px solid #555;
}

.storylog-RichEditor-controls {
  display: inline-block;
  position: relative;
  font-family: Helvetica, sans-serif;
  font-size: 14px;
}

.storylog-RichEditor-controls.storylog-RichEditor-show-link-input {
  padding: 6px 10px;
}

.storylog-RichEditor-controls:last-child {
  border-right: none;
}

.storylog-RichEditor-controls .storylog-RichEditor-linkButton {
  color: #fff;
  text-decoration: none;
}

.storylog-RichEditor-styleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  float: left;
  color: #fff;
  cursor: pointer;
}

.storylog-RichEditor-styleButton:hover {
  color: yellow;
}

.storylog-RichEditor-styleButton:last-child {
  margin-right: 0;
}

.storylog-RichEditor-activeButton path {
  fill: #ffc128;
}

.storylog-RichEditor-activeButton:hover {
  color: #08c;
}

.storylog-RichEditor-controls-block .storylog-RichEditor-styleButton:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.thumb-horizontal {
  border-radius: 3px;
  background: rgb(0 0 0 / 80%);
}

.public-DraftEditor-content .storylog-editor-paragraph,
.public-DraftEditor-content .storylog-editor.indent {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.public-DraftEditor-content .storylog-editor-paragraph:first-child,
.public-DraftEditor-content .storylog-editor.indent:first-child {
  margin-top: 0;
}

.public-DraftEditor-content .storylog-editor-paragraph:last-child,
.public-DraftEditor-content .storylog-editor.indent:last-child {
  margin-bottom: 0;
}

.storylog-editor-checkbox input[type="checkbox"] {
  margin-right: 10px;
  float: left;
}

.storylog-editor-hr {
  display: block;
  width: 60%;
  height: 1px;
  margin: 1.8rem auto;
  padding: 0;
  border: 0;
  border-top: 1px solid #ccc;
}

.storylog-editor-atomic {
  margin: 0;
}

.storylog-editor-atomic img {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #eee;
}

.storylog-editor-atomic-wrapper {
  position: relative;
}

.storylog-editor-atomic-controls {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.2s ease;
}

.storylog-editor-atomic-controls button {
  display: inline-block;
  position: absolute;
  z-index: 1;
  left: -27px;
  width: 22px;
  height: 22px;
  transition: all 0.2s ease;
  border: none;
  border-radius: 11px;
  background-color: #eaeaea;
  color: #6d6d6d;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.storylog-editor-atomic-controls button:focus {
  border-color: #08c;
  outline: none;
  color: #08c;
}

figure.storylog-editor-image {
  margin: 10px 0;
  background: #fff;
}

figure.storylog-editor-image .error-box {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 250px;
  background: #ccc;
  color: red;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

figure.storylog-editor-image img {
  display: block;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 600px;
  margin: 0 auto;
  border: 1px solid #eee;
}

figure.storylog-editor-image img.is-selected {
  box-shadow: 0 0 0 3px #02b875;
}

figure.storylog-editor-image figcaption {
  display: block;
  position: relative;
  z-index: 1;
  padding: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.4;
  text-align: center;
}

figure.storylog-editor-image figcaption .public-DraftStyleDefault-block {
  text-align: center;
}

figure.storylog-editor-image figcaption .description-placeholder {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  left: 0;
  padding: 10px;
  color: #7d7d7d;
  text-align: center;
  user-select: none;
}

figure.storylog-editor-image .description-image-placeholder {
  z-index: 0;
  margin-top: -40px;
}

figure.storylog-editor-image .storylog-editor-image-inner-container {
  position: relative;
}

figure.storylog-editor-image .storylog-editor-image-toolbar-container {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 36px;
  height: 150px;
  min-height: 200px;
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none;
}

figure.storylog-editor-image .storylog-editor-image-toolbar-container button {
  width: 36px;
  height: 36px;
  margin: 5px;
  padding: 5px;
  float: right;
  border: 1px #ccc;
  border-radius: 18px;
  outline: none;
  background-color: #fff;
  box-shadow: 0 1px 20px -8px #636463;
  line-height: 1;
}

figure.storylog-editor-image .storylog-editor-image-toolbar-container .storylog-editor-image-delete-tool {
  width: 100px;
  margin: 10px 0;
  float: right;
}

figure.storylog-editor-image .storylog-editor-image-toolbar-container .storylog-editor-image-delete-tool .red {
  background-color: red;
}

figure.storylog-editor-image .loading {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 255 / 70%);
}

figure.storylog-editor-image .loading .loader,
figure.storylog-editor-image .loading .loader::before,
figure.storylog-editor-image .loading .loader::after {
  width: 2.5em;
  height: 2.5em;
  animation: load7 1s infinite ease-in-out;
  border-radius: 50%;
  animation-fill-mode: both;
}

figure.storylog-editor-image .loading .loader {
  position: relative;
  transform: translateZ(0);
  transform: translateZ(0);
  transform: translateZ(0);
  animation-delay: -0.1s;
  color: #000;
  font-size: 6px;
  text-indent: -9999em;
}

figure.storylog-editor-image .loading .loader::before,
figure.storylog-editor-image .loading .loader::after {
  content: "";
  position: absolute;
  top: 0;
}

figure.storylog-editor-image .loading .loader::before {
  left: -3.5em;
  animation-delay: -0.32s;
}

figure.storylog-editor-image .loading .loader::after {
  left: 3.5em;
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.animated {
  transition: all 0.3s;
  animation-duration: 0.2s;
  animation-fill-mode: both;
}

@keyframes fadeInRight {
  from {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }

  to {
    transform: none;
    opacity: 1;
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

.deleted {
  max-height: 0 !important;
  overflow: hidden;
}
