$black-color: var(--systemGrays01LabelPrimary);

.largetitle-db-heavent-34pt {
  color: $black-color;
  font-family: DBHeavent;
  font-size: 34px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  line-height: normal;
}

.title1-db-heavent-28pt {
  color: $black-color;
  font-family: DBHeavent;
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  line-height: normal;
}

.title2-db-heavent-22pt {
  color: $black-color;
  font-family: DBHeavent;
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  line-height: normal;
}

.title3-db-heavent-20pt {
  color: $black-color;
  font-family: DBHeavent;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  line-height: normal;
}

.headline-db-heavent-17pt {
  color: $black-color;
  font-family: DBHeavent;
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  line-height: normal;
}

.body-db-heavent-17pt {
  color: $black-color;
  font-family: DBHeavent;
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  line-height: normal;
}

.callout-db-heavent-16pt {
  color: $black-color;
  font-family: DBHeavent;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  line-height: normal;
}

.subheadline-db-heavent-15pt {
  color: $black-color;
  font-family: DBHeavent;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  line-height: normal;
}

.footnote-db-heavent-13pt {
  color: $black-color;
  font-family: DBHeavent;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  line-height: normal;
}

.caption1-db-heavent-12pt {
  color: $black-color;
  font-family: DBHeavent;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  line-height: normal;
}

.caption2-db-heavent-11pt {
  color: $black-color;
  font-family: DBHeavent;
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  line-height: normal;
}

.headline-tt-roman-17pt {
  color: $black-color;
  font-family: ThongtermRoman;
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 36px;
}

.body-tt-roman-16pt {
  color: $black-color;
  font-family: ThongtermRoman;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 34px;
}

.callout-tt-roman-16pt {
  color: $black-color;
  font-family: ThongtermRoman;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 34px;
}

.subheadline-tt-roman-15pt {
  color: $black-color;
  font-family: ThongtermRoman;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 32px;
}

.footnote-tt-roman-13pt {
  color: $black-color;
  font-family: ThongtermRoman;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 27px;
}

.caption1-tt-roman-12pt {
  color: $black-color;
  font-family: ThongtermRoman;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 25px;
}

.caption2-tt-roman-11pt {
  color: $black-color;
  font-family: ThongtermRoman;
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 23px;
}

.paragraph-tt-roman-20pt {
  color: $black-color;
  font-family: ThongtermRoman;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 42px;
}

.headline-tt-17pt {
  color: $black-color;
  font-family: Thongterm;
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 31px;
}

.body-tt-16pt {
  color: $black-color;
  font-family: Thongterm;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 29px;
}

.callout-tt-16pt {
  color: $black-color;
  font-family: Thongterm;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 29px;
}

.subheadline-tt-15pt {
  color: $black-color;
  font-family: Thongterm;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 27px;
}

.footnote-tt-13pt {
  color: $black-color;
  font-family: Thongterm;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 23px;
}

.caption1-tt-12pt {
  color: $black-color;
  font-family: Thongterm;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 22px;
}

.caption2-tt-11pt {
  color: $black-color;
  font-family: Thongterm;
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 20px;
}

.paragraph-tt-20pt {
  color: $black-color;
  font-family: Thongterm;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
}

.normal {
  font-style: normal;
}

.italic {
  font-style: italic;
}