$colorsBrandOrange01Primary: var(--colorsBrandOrange01Primary);
$colorsBrandOrange02Darker: var(--colorsBrandOrange02Darker);
$colorsBrandOrange03Lighter: var(--colorsBrandOrange03Lighter);
$colorsBrandOrange04Lightest: var(--colorsBrandOrange04Lightest);
$colorsBrandOrange05FillPrimary: var(--colorsBrandOrange05FillPrimary);
$colorsBrandOrange06FillSecondary: var(--colorsBrandOrange06FillSecondary);
$colorsBrandOrange07FillTertiary: var(--colorsBrandOrange07FillTertiary);
$colorsBrandOrange08FillQuaternary: var(--colorsBrandOrange08FillQuaternary);

$colorsBrandRed01Primary: var(--colorsBrandRed01Primary);
$colorsBrandRed02Darker: var(--colorsBrandRed02Darker);
$colorsBrandRed03Lighter: var(--colorsBrandRed03Lighter);
$colorsBrandRed04Lightest: var(--colorsBrandRed04Lightest);
$colorsBrandRed05FillPrimary: var(--colorsBrandRed05FillPrimary);
$colorsBrandRed06FillSecondary: var(--colorsBrandRed06FillSecondary);
$colorsBrandRed07FillTertiary: var(--colorsBrandRed07FillTertiary);
$colorsBrandRed08FillQuaternary: var(--colorsBrandRed08FillQuaternary);

$colorsBrandTurquoise01Primary: var(--colorsBrandTurquoise01Primary);
$colorsBrandTurquoise02Darker: var(--colorsBrandTurquoise02Darker);
$colorsBrandTurquoise03Lighter: var(--colorsBrandTurquoise03Lighter);
$colorsBrandTurquoise04Lightest: var(--colorsBrandTurquoise04Lightest);
$colorsBrandTurquoise05FillPrimary: var(--colorsBrandTurquoise05FillPrimary);
$colorsBrandTurquoise06FillSecond: var(--colorsBrandTurquoise06FillSecond);
$colorsBrandTurquoise07FillTertiary: var(--colorsBrandTurquoise07FillTertiary);
$colorsBrandTurquoise08FillQuaternary: var(--colorsBrandTurquoise08FillQuaternary);

$systemGrays01LabelPrimary: var(--systemGrays01LabelPrimary);
$systemGrays02LabelSecondary: var(--systemGrays02LabelSecondary);
$systemGrays03LabelTertiary: var(--systemGrays03LabelTertiary);
$systemGrays04LabelQuaternary: var(--systemGrays04LabelQuaternary);
$systemGrays05FillPrimary: var(--systemGrays05FillPrimary);
$systemGrays06FillSecondary: var(--systemGrays06FillSecondary);
$systemGrays07FillTertiary: var(--systemGrays07FillTertiary);
$systemGrays08FillQuaternary: var(--systemGrays08FillQuaternary);

$colorsWhite: var(--colorsWhite);
$colorsFacebook: var(--colorsFacebook);
$systemComponentsDisabled: var(--systemComponentsDisabled);

$componentsBgBase: var(--componentsBgBase);
$componentsBgGrouped01: var(--componentsBgGrouped01);
$componentsBgGrouped02: var(--componentsBgGrouped02);
$componentsDivider: var(--componentsDivider);

$opacityBlack20: var(--opacityBlack20);
$opacityBlack40: var(--opacityBlack40);
$opacityBlack60: var(--opacityBlack60);
$opacityBlack80: var(--opacityBlack80);

$opacityWhite20: var(--opacityWhite20);
$opacityWhite60: var(--opacityWhite60);
$opacityWhite80: var(--opacityWhite80);

$overlayBGModal: var(--overlayBGModal);

$colorsBrandWarmBlack01LabelPrimary: var(--colorsBrandWarmBlack01LabelPrimary);
$colorsBrandWarmBlack02LabelSecondary: var(--colorsBrandWarmBlack02LabelSecondary);
$colorsBrandWarmBlack03LabelTertiary: var(--colorsBrandWarmBlack03LabelTertiary);
$colorsBrandWarmBlack04LabelQuaternary: var(--colorsBrandWarmBlack04LabelQuaternary);
$colorsBrandWarmBlack05FillPrimary: var(--colorsBrandWarmBlack05FillPrimary);
$colorsBrandWarmBlack06FillSecondary: var(--colorsBrandWarmBlack06FillSecondary);
$colorsBrandWarmBlack07FillTertiary: var(--colorsBrandWarmBlack07FillTertiary);

$colorsBrandPaper01Primary: var(--colorsBrandPaper01Primary);
$colorsBrandPaper02Darker: var(--colorsBrandPaper02Darker);
$colorsBrandPaper03Lighter: var(--colorsBrandPaper03Lighter);
$colorsBrandPaper04Lightest: var(--colorsBrandPaper04Lightest);
$colorsBrandPaper05FillPrimary: var(--colorsBrandPaper05FillPrimary);
$colorsBrandPaper06FillSecondary: var(--colorsBrandPaper06FillSecond);
$colorsBrandPaper07FillTertiary: var(--colorsBrandPaper07FillTertiary);

$colorsPintoBlueprint01Primary: var(--colorsPintoBlueprint01Primary);
$colorsPintoBlueprint02Darker: var(--colorsPintoBlueprint02Darker);
$colorsPintoBlueprint03Lighter: var(--colorsPintoBlueprint03Lighter);
$colorsPintoBlueprint04Lightest: var(--colorsPintoBlueprint04Lightest);
$colorsPintoBlueprint05FillPrimary: var(--colorsPintoBlueprint05FillPrimary);
$colorsPintoBlueprint06FillSecondary: var(--colorsPintoBlueprint06FillSecond);
$colorsPintoBlueprint07FillTertiary: var(--colorsPintoBlueprint07FillTertiary);