/* stylelint-disable property-no-vendor-prefix */
.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 6px;
  background-color: var(--colorsBrandWarmBlack05FillPrimary);
  background-image: linear-gradient(var(--colorsBrandRed01Primary), var(--colorsBrandRed01Primary));
  background-repeat: no-repeat;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    transition: background 0.3s ease-in-out;
    border-radius: 50%;
    background-color: var(--colorsBrandRed01Primary);
    cursor: pointer;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    transition: background 0.3s ease-in-out;
    border-radius: 50%;
    background-color: var(--colorsBrandRed01Primary);
    cursor: pointer;
  }

  &::-ms-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    transition: background 0.3s ease-in-out;
    border-radius: 50%;
    background-color: var(--colorsBrandRed01Primary);
    cursor: pointer;
  }
}