$bgColor: var(--componentsBgGrouped01);
$labelSecondary: var(--colorsBrandWarmBlack02LabelSecondary);
$textLinkColor: var(--colorsRed01Primary); /** ci-textLinkColor */

.contentRawStateElement {
  display: flex;
  flex-direction: column;
  line-height: 2.4;
  word-wrap: break-word;

  a {
    color: $textLinkColor;
    text-decoration: underline;
  }

  p.storylog-editor {
    margin-top: 0;
    margin-block-end: 1em;
  }
}

.emptyContent {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  background-color: $bgColor;
  color: $labelSecondary;
  font-size: 16px;
}
