// magic
.toggle {
  --size: 1.5rem;

  width: var(--size);
  height: var(--size);
  transition: all 500ms;
  border-radius: 999px;
  outline: none;
  box-shadow: inset calc(var(--size) * 0.33) calc(var(--size) * -0.25) 0;
  color: #3bc6c6;
  cursor: pointer;
  appearance: none;
}

.day {
  --ray-size: calc(var(--size) * -0.4);
  --offset-orthogonal: calc(var(--size) * 0.65);
  --offset-diagonal: calc(var(--size) * 0.45);

  transform: scale(0.75);
  box-shadow:
    inset 0 0 0 var(--size),
    calc(var(--offset-orthogonal) * -1) 0 0 var(--ray-size),
    var(--offset-orthogonal) 0 0 var(--ray-size),
    0 calc(var(--offset-orthogonal) * -1) 0 var(--ray-size),
    0 var(--offset-orthogonal) 0 var(--ray-size),
    calc(var(--offset-diagonal) * -1) calc(var(--offset-diagonal) * -1) 0 var(--ray-size),
    var(--offset-diagonal) var(--offset-diagonal) 0 var(--ray-size),
    calc(var(--offset-diagonal) * -1) var(--offset-diagonal) 0 var(--ray-size),
    var(--offset-diagonal) calc(var(--offset-diagonal) * -1) 0 var(--ray-size), ;
  color: #ffb900;
}