$color-title: #000;
$color-text: #8a8a8e;
$color-badge: #ff5a65;

.badge {
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 4px;
  border-radius: 4px;
  background-color: $color-badge;
  color: #fff;
  font-family: DBHeavent;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  text-align: center;
}

.title {
  margin-top: 8px;
  margin-bottom: 4px;
  overflow: hidden;
  color: $color-title;
  font-family: DBHeavent;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text1 {
  margin-bottom: 4px;
  overflow: hidden;
  color: $color-text;
  font-family: DBHeavent;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text2 {
  overflow: hidden;
  color: $color-text;
  font-family: DBHeavent;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.metaBox {
  display: flex;
  margin-top: 12px;
}

.metaItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  color: $color-text;
  font-family: DBHeavent;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;

  :first-child {
    margin-right: 4px;
  }
}
