.openedModal {
  z-index: -99;
  height: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch !important;
}

div[role="dialog"] {
  width: 100%;
  animation-name: fadeIn;
  animation-duration: 0.3s;
  animation-fill-mode: both;

  > div[role="document"] {
    max-width: calc(100vw - 35px);
  }
}
