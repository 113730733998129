.iconWrapper {
  display: inline-flex;
  position: relative;
  align-self: center;
  width: var(--iconSize);
  height: var(--iconSize);
}

.svg {
  &:hover {
    svg {
      position: absolute;
      width: var(--iconSize);
      height: var(--iconSize);

      path,
      g {
        fill: var(--fillColor) !important;
      }
    }
  }

  svg {
    position: absolute;
    width: var(--iconSize);
    height: var(--iconSize);

    path,
    g {
      fill: var(--fillColor) !important;
    }
  }
}
