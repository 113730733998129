//use css variable to pass variable from inlinestyle
.outerContainer {
  position: relative;
  width: 100%;
  max-width: var(--maxWidth, 100%);
  margin: 0;
  overflow: hidden;
  background-color: var(--backgroundColor, transparent);
  filter: var(--grayScale, "");

  &::before {
    content: "";
    display: block;
    padding-top: calc(100% / var(--aspectRatio, 1));
  }
}

.innerContainer {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;

  /* -1px for anti-aliasing */
  left: -1px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
