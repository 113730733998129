/* commons */

.fictionlog-editor {
  display: flex;
  flex-direction: column;
  font-family: 'Thongterm', 'Helvetica', 'arial', 'sans-serif';
  --editor-placeholder-color: #ccc;
  --editor-placeholder-content: 'พิมพ์เนื้อหาของคุณที่นี้';
  --editor-figcaption-content: 'คำอธิบาย:';
  /* color schema */
  --editor-hr-color: #ccc;
  --editor-code-bg: #ccc;
  --editor-selecting-img-outline: 4px solid #ffb900;
  --toolbar-bg: var(--componentsBgGrouped02);
  --toolbar-border: 1px solid #ccc;
  --toolbar-button-bg: var(--componentsBgGrouped02);
  --toolbar-button-bg-active: #fff;
  --toolbar-button-color: var(--colorsBrandWarmBlack02LabelSecondary);
  --toolbar-button-color-active: #ffb900;
  --toolbar-border-divider: 1px solid rgb(224, 224, 224);
}

/* overide */

.ProseMirror-focused {
  outline: 0 !important;
}

.ProseMirror {
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: break-spaces;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  font-feature-settings: 'liga' 0; /* the above doesn't seem to work in Edge */
}

.ProseMirror .show-placeholder::before {
  float: left;
  color: var(--editor-placeholder-color);
  pointer-events: none;
  height: 0;
  font-style: italic;
}

.wysiwyg-editor p.show-placeholder:first-child::before {
  content: var(--editor-placeholder-content);
}

/* toolbar */

.editor-toolbar {
  display: flex;
  height: 40px;
  padding: 0;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  margin: 0 auto;
  background: var(--toolbar-bg);
  border-bottom: var(--toolbar-border);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* opacity: 0; */
}

.editor-toolbar > div {
  display: flex;
  width: auto;
  height: 28px;
  padding: 0 5px;
  border-right: var(--toolbar-border-divider);
}

.editor-toolbar > div:last-child {
  border-right: 0;
}

.editor-toolbar.isFocus {
  display: flex;
  /* opacity: 1; */
}

.toolbar-item {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  cursor: pointer;
  height: 100%;
  padding: 0 12px;
  background: var(--toolbar-button-bg);
}
.toolbar-item > div {
  width: 24px;
  height: 24px;
  color: var(--toolbar-button-color);
}
.toolbar-item > div > svg {
  fill: var(--toolbar-button-color);
}
.toolbar-item:disabled {
  opacity: 0.2;
}

.toolbar-item[data-active='true'] {
  font-weight: bold;
  background: var(--toolbar-button-bg-active);
  color: var(--toolbar-button-color-active);
  opacity: 1;
  border-radius: 8px;
  overflow: hidden;
}

.toolbar-item[data-active='true'] svg {
  fill: var(--toolbar-button-color-active);
}

.toolbar-item.group {
  width: 75px;
}

/* text editor style */

.wysiwyg-editor {
  min-height: 300px;
}

.wysiwyg-editor blockquote {
  font-weight: 300;
  display: block;
  text-align: center;
  padding: 0 50px;
  font-style: italic;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wysiwyg-editor blockquote::before,
.wysiwyg-editor blockquote::after {
  content: ' ';
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0ODguMjU2IiBoZWlnaHQ9IjQ5LjA5OCIgdmlld0JveD0iMCAwIDQ4OC4yNTYgNDkuMDk4Ij48cGF0aCBmaWxsPSJub25lIiBkPSJNMCAwaDQ4OC4yNTZ2NDkuMDk4SDB6Ii8+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjQzdDOUNCIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGQ9Ik0yMTMuNzg4IDI2LjE3NEg4LjIwNE00ODAuMDUyIDI2LjE3NEgyNzQuNDY4Ii8+PGcgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGw9IiNDN0M5Q0IiPjxwYXRoIGQ9Ik0yNDguODI2IDI5LjI2NGgtMi44MDVsMS4yMzQtOS40M2gzLjY1MWMtLjY5OSAzLjE3MS0xLjM5IDYuMzA3LTIuMDggOS40M3pNMjQxLjcyOSAyOS4yNjJoLTIuNzk4bDEuMjItOS40MjhoMy42NTZsLTIuMDc4IDkuNDI4eiIvPjwvZz48L3N2Zz4=);
  background-size: cover;
  background-position: 50%;
  width: 60%;
  height: 50px;
  display: block;
  position: relative;
  margin: 10 px auto;
}

.wysiwyg-editor hr {
  width: 60%;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid var(--editor-hr-color);
  margin: 1.8rem auto;
  padding: 0;
}

.wysiwyg-editor code {
  background: var(--editor-code-bg);
}

/* image */
.wysiwyg-editor figure {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.wysiwyg-editor figure img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  box-sizing: border-box;
  max-height: 600px;
}
.wysiwyg-editor img.ProseMirror-selectednode {
  outline: var(--editor-selecting-img-outline);
}
.wysiwyg-editor figcaption {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0;
  font-weight: 300;
  font-style: normal;
  text-align: center;
  position: relative;
  padding: 8px;
  z-index: 1;
}
.wysiwyg-editor figcaption::before {
  content: var(--editor-figcaption-content);
  padding-right: 6px;
}

.wysiwyg-editor .indent {
  text-indent: 50px;
}

.wysiwyg-editor .center {
  text-align: center;
}

.wysiwyg-editor .right {
  text-align: right;
}

.editor-group-tool {
  display: flex;
  flex-direction: row;
}

.editor-group-expand-tool {
  transition: width 0.3s;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

/* mobile */
.editor-toolbar .show-in-desktop {
  display: flex;
}

.editor-toolbar .show-in-mobile {
  display: none;
}

@media (max-width: 768px) {
  .editor-toolbar {
    height: 75px;
    margin: 0;
    width: 100%;
    padding: 0;
    left: 0;
    border-radius: 0;
    overflow-x: scroll;
    display: flex;
    justify-content: flex-start;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }

  .editor-toolbar::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .editor-toolbar {
    border-top: var(--toolbar-border);
    border-bottom: 0;
  }

  .editor-toolbar > div {
    border-right: 0;
    padding: 0;
  }
}
